
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlceEntity} from "@/entity/IlceEntity";

@Component({
  components: {DatabasePicker},
})
export default class IlcePicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId !: any;
  @Prop() returnObject!: string | boolean;
  @Prop() rules!: string;
  @Prop() color!: string;
  @Prop() il!: any;

  localValue!: number | IlceEntity;
  items: Array<IlceEntity> = [];

  get message(): string {
    return this.il ? "" : "Lütfen İl Seçiniz";
  }

  get action(): string {
    return "/api/v1/sehir/" + this.il + "/ilce";
  }

  get isReturnId() {
    return this.returnId || this.returnId === "";
  }

  get isReturnObject() {
    return this.returnObject || this.returnObject === "";
  }

  @Watch('il')
  onIlChange() {
    if (this.isReturnObject) {
      this.$http
          .get("/api/v1/sehir/" + this.il + "/ilce")
          .then((response: any) => {
            this.items = response.data;
            if (this.localValue && !this.items.find((item) => (item.id == this.localValue)))
              delete this.$data.localValue;
          });
    }
  }

  onIlceChange(v: IlceEntity) {
    this.$emit('ilce-adi', v.isim);
    this.input()
  }
}
