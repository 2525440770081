
import {Component, Mixins, Prop} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  directives: {
    mask
  },
})
export default class PostaKodu extends Mixins(SimpleInputMixin) {
  @Prop({})  value!: string;
  notEmptyRuleText = "5 karakter olmalıdır.";
  mask = "#####";
  localValue = this.value ? this.value : null;

  notEmptyRule() {
    if(this.localValue){
      return (( this.localValue.length ==0 ) || (this.localValue.length == 5)) || this.notEmptyRuleText;
    }
    return true;
  }

  validate() {
    this.$emit("value", this.localValue);
  }

};
