
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import EPosta from "@/components/inputs/EPosta.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import MersisNumarasi from "@/components/inputs/MersisNumarasi.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  components: {
    FormWrapper,
    EPosta,
    VergiNumarasi,
    AdresForm,
    MersisNumarasi
  }
})
export default class BuroForm extends Mixins(ObjectInputMixin) {
  @Prop() action !: string;
  @Prop() hideButton!: any;
  @Ref('formWrapper') formWrapper!: FormWrapper;
  selectedRadio=null
  buroBaslik = ''

  BuroTipi = [
    {id: 0, icerik: "Büro"},
    {id: 1, icerik: "Kurum"},
    {id: 2, icerik: "Avukat Ortaklığı"},
  ]
  onClose(){
    this.selectedRadio = null
    this.$emit("onClose");

  }
  @Watch('selectedRadio')
  changeRadio(){
    if (this.selectedRadio==='buroSec'){
      this.buroBaslik = 'Büro*'
    }else if(this.selectedRadio==='kurumSec'){
      this.buroBaslik = 'Kurum*'
    }else if(this.selectedRadio==='avukatSec'){
      this.buroBaslik = 'Avukat Ortaklığı*'
    }
  }

  get localAction() {
    if (this.localValue && this.localValue.id) {
      return "/api/v1/buro/";
    }
    return this.action;
  }

  get buroIsimLabel(){
    if(this.selectedRadio==='buroSec') {
        return 'Büro Seçiniz *'
    }else if(this.selectedRadio==='kurumSec'){
      return 'Kurum Seçiniz *'
    }else if(this.selectedRadio==='avukatSec'){
      return 'Avukat Ortaklığı Seçiniz *'
    }

  }

  get buroIsimRule() {
      if (this.localValue.isim) {
          if (/^[A-Za-z0-9\sÇÖÜĞŞİçöüğşı]+$/.test(this.localValue.isim)) {
              return true;
          } else {
              return 'Yasak karakter. (", \, !, &, vb.) karakterler kullanılamaz.';
          }
      } else {
          return 'Lütfen bu alanı doldurun.';
      }


  }

    get vergiDairesiRule() {
        if (this.localValue.vergi_dairesi) {
            if (this.localValue.vergi_dairesi.match(/[^a-zA-ZğüşıöçĞÜŞİÖÇ\s]/g) == null)
                return true;
            else
                return `Yasak karakter. (1, 2, !, ? vb.) karakterler kullanılamaz.`;
        }
    }




  save(goDetay: boolean = false) {
    this.formWrapper.onFly = true;
    if (this.localValue.id) {
      this.$http.put(this.localAction + this.localValue.id, this.localValue).then(() => {
        this.$http.post(this.localAction + this.localValue.id + '/adres', this.localValue.adres).then(() => (this.$emit("onSuccess")));
      }).finally(() => (this.formWrapper.onFly = false));
    } else {
      this.$http.post(this.localAction, this.localValue).then((response) => {
        if (goDetay)
          this.$router.push(ProgramPaths.ofispro + '/buro/' + response.data.id)
        this.$emit("onSuccess");
      }).finally(() => (this.formWrapper.onFly = false));
    }

  }

  get isHideButton() {
    return this.hideButton || this.hideButton === "";
  }
}
